import React from 'react'
import Layout from '../../components/layout/Layout'
import { SeriesPage } from '../../components/series/SeriesPage'

const ExploringVimSeries = () => {
  const name = 'exploring-vim'
  const title = 'Exploring Vim'
  const subtitle = 'Venture forth in the exotic land of Vim'
  const image = '/images/become-1-percent-better.jpg'
  const imageAlt = 'A text that reads: Become 1 percent better every day'

  return (
    <Layout>
      <SeriesPage
        name={name}
        title={title}
        subtitle={subtitle}
        image={image}
        imageAlt={imageAlt}
      >
        <p>
          Follow along as I dive deep into the mysterious world of Vim and learn
          how to write code at the speed of thought.
        </p>
        <p>
          Vim is this wonderful editor that is different from anything else out
          there and which has persevered over the decades increasing in usage
          and gathering a crowd of passionate fans in love with Vim and its
          awesome capabilities.
        </p>
        <p>
          In this series I dive into what makes Vim special, why you should care
          to use a decades old editor in this modern age of software development
          and how to be a super effective developer using Vim.
        </p>
      </SeriesPage>
    </Layout>
  )
}

export default ExploringVimSeries
